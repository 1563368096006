[data-view="messageConfiguration"] {
	.expired {
		color: #c23934;
		text-transform: uppercase;
	}

	#mobile-app-icon {
		width: 20px;
		height: 20px;
		margin-top: -1px;
		margin-right: 5px;
	}
}

#messageConfiguration {
	.heading {
		text-transform: uppercase;
		margin-bottom: 10px;
		color: $default-subheading-color;
		font-weight: 300;
	}

	#mobile-application-container {
		.slds-combobox_container {
			width: 400px;
		}

		.slds-icon_container {
			&.slds-icon-utility-check {
				color: #0070d2;
			}
		}

		.disabled-text {
			color: #999999;
		}

		.expired {
			color: #c23934;
			text-transform: uppercase;
		}
	}

	.active-message-timeframe-container {
		margin-top: 30px;

		.radio-container {
			display: flex;
			flex-direction: row;
			margin-bottom: 25px;
			
			input[type="radio"] {
				cursor: pointer;
			}
			
			& > label {
				margin-left: 10px;
				margin-top: -3px;
				
				& > div {
					display: flex;
					flex-direction: column;
					
					& > span {
						cursor: pointer;
					}
				}
			}
			
			.slds-text-heading {
				font-size: 0.9rem;
			}
		}

		.custom-timeframe-scheduler-container {
			margin-top: 10px;

			&.hidden {
				display: none;
			}

			.date-time-picker {
				margin-bottom: 10px;

				input {
					&::placeholder {
						color: $default-heading-color;
						opacity: 1;
					}

					&:-ms-input-placeholder {
						color: $default-heading-color;
					}

					&::-ms-input-placholder {
						color: $default-heading-color;
					}
				}
			}

			.timezone-picker {
				input {
					&::placeholder {
						color: $default-heading-color;
						opacity: 1;
					}

					&:-ms-input-placeholder {
						color: $default-heading-color;
					}

					&::-ms-input-placholder {
						color: $default-heading-color;
					}
				}

				.desc {
					color: $default-subheading-color;
					font-size: $default-label-font-size;
					margin-top: 0.125rem;
				}
			}

			.slds-input {
				/* Override slds-visual-picker's input styling */
				width: 100%;
				position: relative;
				height: initial;
				border: 1px solid #dddbda;
				clip: initial;
				overflow: initial;
				margin: 0;
				padding: 0 1rem 0 0.75rem;
			}

			#timezone-dropdown-listbox {
				/* Override slds-visual-picker's span tag styling */
				.slds-media {
					display: flex;

					.slds-media__figure {
						display: inline-block;
					}

					.slds-media__body {
						display: inline-block;
					}
				}
			}
		}
		
		.dynamic-timeframe-container {
			.container-body {
				display: flex;
				
				> .slds-form-element {
					min-width: 48px;
				}
				
				.components-container {
					display: flex;
					flex-wrap: wrap;
					margin-top: 0.5rem;
					
					.break {
						flex-basis: 100%;
						height: 0;
					}
					
					.controls {
						display: flex;
						
						.slds-form-element:first-child { // Input field
							width: 35%;
							margin-right: 0.5rem;
							
							/* Chrome, Safari, Edge, Opera */
							input::-webkit-outer-spin-button,
							input::-webkit-inner-spin-button {
								-webkit-appearance: none;
								margin: 0;
							}
							
							/* Firefox */
							input[type=number] {
								-moz-appearance: textfield;
							}
						}
						
						.slds-form-element:last-child { // Combobox
							width: 40%;
						}
					}
					
					.error-text {
						color: #c23934;
					}
				}
			}
		}
	}
}

.test-sends-button {
	padding-top: 1rem;
	padding-bottom: 1rem;
	button {
		width: 300px;
	}
}

.progress-modal-content {
	display: inline-flex;
	width: 100%;
	padding: 2rem;
	justify-content: center;
	min-height: 25rem;
	height: 70vh;
	.slds-visual-picker {
		margin-right: 3rem;
	}
	.slds-visual-picker:last-child {
		margin-right: 0;
	}
}

.progress-modal-footer {
	display: flex;
	justify-content: center;
	height: 2rem;
	.slds-progress {
		margin: 1rem;
	}
	.progress-back, .progress-next{
		width: 5rem;
	}
}

#test-method-content {
	padding-top: 3rem;
}

#progress-indicator {
	max-width: 40%
}

.slds-modal__header {
	border-bottom-style: none;
	margin-top: 0;
}

.slds-icon_large {
	height: 4rem;
	width: 4rem;
}

label[for='contact-id-input'] {
	text-align: center;
	width: 35rem;
}

#contact-id-input {
	width: 35rem;
	margin-top: 5rem;
}

.slds-table_header-fixed_container {
	border: 0;
}

.empty {
	padding: 1rem;
	text-align: center;
	font-style: italic;
}

.select-option-table {
	height: 50vh;
	overflow: scroll;
	min-height: 15rem;
}

thead > tr > th.slds-text-align_right {
	width: 1.5rem !important;
}

.slds-table_header-fixed_container, .slds-table--header-fixed_container {
	background: none;
}

.contacts-selected-count {
	text-align: right;
	height: 21px;
}

.count-over-limit {
	color: var(--slds-g-color-error-base-40,#ea001e);
}

#test-send {
	padding-bottom: 1rem;

	.contacts-selected-count {
		display: none;
	}

	.itemsOverMaxSelection {
		.contacts-selected-count {
			display: block;
		}

		.select-option-table {
			thead > tr > th > div {
				display: none;
			}
		}
	}

	.select-option-table {
		.slds-checkbox_faux {
			margin-top: -2px;
		}
	}
}

.progress-modal-test-results {
	width: 70%;
	.slds-timeline__item_success:before {
		background: #3ba755;
	}

	.slds-timeline__item_failure:before {
		background: red;
	}

	.slds-timeline__item_progress:before {
		background: #939393;
	}

	.slds-timeline__item_queued:before {
		background: #fcc003;
	}

	.slds-timeline__item_last:before {
		background: white;
	}

	.timeline-view-headers:hover {
		background-color: transparent;
	}

	.label-style-margin {
		margin: 0px;
	}

	.trigger-api-retry > svg {
		width: 0.8rem;
		height: 0.8rem;
		margin-left: 5px;
	}

	.pre-loader {
		margin-left: 10px;
	}

	.hideExpandButton {
		padding-left: 1.7rem;
	}

	.dummy-opacity {
		opacity: 0.3;
	}

	.slds-timeline__item_expandable .slds-media__figure .slds-button_icon {
		margin-right: 0;
	}

	.toggle-info-btn {
		padding-right: 0.7rem;
	}
}

#progress-indicator-tooltip-0, #progress-indicator-tooltip-1, #progress-indicator-tooltip-2, #progress-indicator-tooltip-3{
	display: none;
}

.hide-visibility {
	visibility: hidden;
}